import "./Donate.css";
import { donateSmartContractAbi } from "../donateSmartContractAbi";
import { bep20Abi } from "../bep20Abi";
import {
  Button,
  Input,
  TextArea,
  Dropdown,
  Loading,
  iconTypes,
  Modal,
  Icon,
} from "web3uikit";
import {
  useTokenPrice,
  useWeb3ExecuteFunction,
  useMoralisCloudFunction,
  useMoralisQuery,
  useApiContract,
  useMoralis,
} from "react-moralis";
import { useState, useEffect } from "react";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import { message } from "antd";

const WBNB_BSC = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
const WBNB_ETH = "0x418D75f65a02b3D53B2418FB8E1fe493759c7605";
const WETH_BSC = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
const WETH_ETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
const WBTC_BSC = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
const WBTC_ETH = "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
const USDC_BSC = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
const USDC_ETH = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
const BUSD_BSC = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
const BUSD_ETH = "0x4Fabb145d64652a948d72533023f6E7A623C7C53";
const BSC_CHAIN_ID = "0x38";
const ETH_CHAIN_ID = "0x1";
const BSC_DONATION_CONTRACT_ADDRESS = "0x98AB61297BcefBa50C895bf3c7975B416b8a4cCA";
const ETH_DONATION_CONTRACT_ADDRESS = "0x9D7E0a7Ea956c7FC3836fFbfbC939eE9eB04eE7F";


function Donate({ selectedChainId }) {
  const { account, isAuthenticated, Moralis } = useMoralis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [streamerUsername, setStreamerUsername] = useState();
  const [streamerWalletAddress, setStreamerWalletAddress] = useState();
  const [contributorName, setContributorName] = useState();
  const [donationAmount, setDonationAmount] = useState();
  const [donationMessage, setDonationMessage] = useState("");
  const [chainId, setChainId] = useState(selectedChainId);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState();
  const [selectedTokenDecimals, setSelectedTokenDecimals] = useState(18);
  const [selectedTokenName, setSelectedTokenName] = useState();
  const [selectedTokenAllowance, setSelectedTokenAllowance] = useState();
  const [tokenAmount, setTokenAmount] = useState();
  const [tempTokenAmount, setTempTokenAmount] = useState();
  const [selectedTokenPrice, setSelectedTokenPrice] = useState(1);
  const [isTxPending, setIsTxPending] = useState(false);
  const [txResult, setTxResult] = useState();
  const [txHash, setTxHash] = useState();
  const { fetch: fetchStreamer } = useMoralisCloudFunction(
    "searchStreamer",
    {
      streamerUsername: streamerUsername,
    },
    { autoFetch: false }
  );

  const { fetch: postDonationAlert } = useMoralisCloudFunction(
    "postDonationAlert",
    {
      transactionHash: txHash,
      streamerUsername: streamerUsername,
      identifier: account,
      amount: donationAmount,
      userName: `${contributorName ? `${contributorName}` : "Someone"}`,
      message: `${donationMessage ? `${donationMessage}` : ""}`,
    },
    { autoFetch: false }
  );

  const { fetchTokenPrice, data: formattedPriceData } = useTokenPrice({
    address: getSelectedTokenAddress(),
    chain: chainId == BSC_CHAIN_ID ? BSC_CHAIN_ID : ETH_CHAIN_ID,
  });

  const { runContractFunction: getTokenAllowance } = useApiContract({
    abi: bep20Abi,
    address: getSelectedTokenAddress(),
    functionName: "allowance",
    chain: chainId == BSC_CHAIN_ID ? BSC_CHAIN_ID : ETH_CHAIN_ID,
    params: {
      owner: account,
      spender: getCorrectContractAddress(),
    },
  });

  const { fetch: approve } = useWeb3ExecuteFunction({
    abi: bep20Abi,
    contractAddress: getSelectedTokenAddress(),
    functionName: "approve",
    params: {
      spender: getCorrectContractAddress(),
      amount: Moralis.Units.Token("100000000", "18"),
    },
  });
  const { fetch: executeDonation } = useWeb3ExecuteFunction({
    abi: donateSmartContractAbi,
    contractAddress: getCorrectContractAddress(),
    functionName: "donate",
    params: {
      streamerAddress: streamerWalletAddress,
      tokenAddress: getSelectedTokenAddress(),
      amount: tokenAmount,
    },
  });

  useEffect(() => {
    setChainId(selectedChainId);
  }, [selectedChainId]);
  useEffect(() => {
    if (searchParams.has("streamer")) {
      const username = searchParams.get("streamer");
      if (username) {
        setStreamerUsername(username);
      } else {
        setStreamerUsername(null);
      }
    }

  }, []);

  useEffect(() => {

    console.log(selectedTokenName);
    if (selectedTokenName) {
      fetchPrice();
    }
  }, [selectedTokenName, chainId]);

  useEffect(() => {
    console.log(formattedPriceData);
    if (formattedPriceData) {

      setSelectedTokenPrice(formattedPriceData.usdPrice);
      setSelectedTokenDecimals(formattedPriceData.nativePrice.decimals);
    }
  }, [formattedPriceData]);

  useEffect(() => {
    const fetchData = async () => {
      const streamer = await fetchStreamer();

      if (streamer) {
        console.log(streamer.get("walletAddress"));
        setStreamerWalletAddress(streamer.get("walletAddress"));
      }
    };

    // call the function
    if (streamerUsername) {
      fetchData();
    }
  }, [streamerUsername]);

  useEffect(() => {
    //console.log(selectedTokenPrice + " " + selectedTokenDecimals);
    if (!donationAmount) return;
    setTempTokenAmount(
      (
        (donationAmount / selectedTokenPrice) *
        10 ** selectedTokenDecimals
      ).toFixed(0)
    );
  }, [donationAmount, formattedPriceData]);

  const fetchPrice = async () => {
    fetchTokenPrice();
  };

  const fetchAllowance = async () => {
    setIsTxPending(true);
    console.log(account);
    const result = await getTokenAllowance();
    setIsTxPending(false);
    setSelectedTokenAllowance(result);
    console.log(result);
  };

  useEffect(() => {
    if (account && selectedTokenName) {
      console.log(account);
      fetchAllowance();
    }
  }, [account, selectedTokenName, chainId]);

  function getSelectedTokenAddress() {
    //console.log(selectedTokenName);
    //console.log(chainId);
    switch (selectedTokenName) {
      case "WBNB":
        return chainId == BSC_CHAIN_ID ? WBNB_BSC : WBNB_ETH;
      case "WETH":
        return chainId == BSC_CHAIN_ID ? WETH_BSC : WETH_ETH;
      case "WBTC":
        return chainId == BSC_CHAIN_ID ? WBTC_BSC : WBTC_ETH;
      case "BUSD":
        return chainId == BSC_CHAIN_ID ? BUSD_BSC : BUSD_ETH;
      case "USDC":
        return chainId == BSC_CHAIN_ID ? USDC_BSC : USDC_ETH;
      default:
        return "";
    }
  }

  function getCorrectContractAddress() {
    return chainId == BSC_CHAIN_ID ? BSC_DONATION_CONTRACT_ADDRESS : ETH_DONATION_CONTRACT_ADDRESS;
  }

  const approveToken = async () => {
    setIsTxPending(true);
    approve({
      onSuccess: (tx) =>
        tx.wait().then(() => {
          setIsTxPending(false);
          setSelectedTokenAllowance(1);
        }),
    });

  }

  const startDonationFlow = async () => {
    if (!donationAmount) return;
    const tokenPrice = await fetchTokenPrice();
    var usdPrice = tokenPrice.usdPrice;
    console.log(tokenPrice);
    var tokenDecimals = tokenPrice.nativePrice.decimals;
    console.log(tokenDecimals);
    setTokenAmount(
      ((donationAmount / usdPrice) * 10 ** tokenDecimals).toFixed(0)
    );
    console.log(tokenAmount);
  };

  useEffect(() => {
    const createTx = async () => {
      console.log(tokenAmount);
      setIsTxPending(true);
      executeDonation({
        onSuccess: (tx) =>
          tx.wait().then(() => {
            setTxHash(tx.hash);
            setIsTxPending(false);
            setTxResult({
              result: "OK",
              message: "Tx succeeded",
              icon: "checkmark",
              iconColor: "#47d542",
              hash: tx.hash,
            });
          }),

        onError: (error) => {
          setIsTxPending(false);
          console.log(error);
          if (error.message.includes("User denied transaction signature"))
            return;
          setTxResult({
            result: "KO",
            message: `Tx failed: ${error.message}`,
            icon: "exclamation",
            iconColor: "#dc2d2d",
            hash: "",
          });
        },
      });
    };

    // call the function
    if (tokenAmount) {
      createTx();
    }
  }, [tokenAmount]);

  useEffect(() => {
    if (txHash) {
      postDonationAlert();
    }
  }, [txHash]);

  return (
    <div className="mainContainerDonate">
      {streamerUsername && (
        <div className="donateToContainer">
          <h1>Donate to {streamerUsername}</h1>
        </div>
      )}

      <div className="nameContainer" id="nameInput">
        <Input
          label="Name"
          name="Name Input"
          labelBgColor="#282c34"
          theme="primary"
          width="100%"
          value={contributorName}
          onChange={(event) => setContributorName(event.target.value)}
        />
      </div>

      <div className="amountContainer">
        <div className="amountInput" id="amountInput">
          <Input
            className="customInput"
            width="100%"
            label="Amount"
            name="Amount Input"
            type="number"
            labelBgColor="#282c34"
            value={donationAmount}
            validation={{
              required: true,
            }}
            onChange={(event) => {
              setDonationAmount(event.target.value);
            }}
          />
          {donationAmount && tempTokenAmount && (
            <label className="amountInCryptoLabel">
              {(tempTokenAmount / 10 ** 18).toFixed(5)} {selectedTokenName}
            </label>
          )}
        </div>

        <div className="tokenDropdown" id="dropdown">
          <Dropdown
            width="100%"
            label="Token: "
            onChange={(selectedOption) => {
              setSelectedTokenName(selectedOption.label);
            }}
            options={[
              {
                id: "0",
                label: "WBNB",
              },
              {
                id: "1",
                label: "WBTC",
              },
              {
                id: "2",
                label: "WETH",
              },
              {
                id: "3",
                label: "BUSD",
              },
              {
                id: "4",
                label: "USDC",
              },
            ]}
          />
        </div>

      </div>
      <div className="amountButtonsContainer">
        <Button
          id="amountButton1"
          className="amountButton"
          onClick={function noRefCheck() {
            console.log(getSelectedTokenAddress());
            setDonationAmount(1);
          }}
          text="$1"
          theme="primary"
          type="button"
        />
        <Button
          id="amountButton2"
          onClick={function noRefCheck() {
            setDonationAmount(2);
          }}
          text="$2"
          theme="primary"
          type="button"
        />
        <Button
          id="amountButton3"
          onClick={function noRefCheck() {
            setDonationAmount(5);
          }}
          text="$5"
          theme="primary"
          type="button"
        />
        <Button
          id="amountButton4"
          onClick={function noRefCheck() {
            setDonationAmount(10);
          }}
          text="$10"
          theme="primary"
          type="button"
        />
        <Button
          id="amountButton5"
          onClick={function noRefCheck() {
            setDonationAmount(50);
          }}
          text="$50"
          theme="primary"
          type="button"
        />
        <Button
          id="amountButton5"
          onClick={function noRefCheck() {
            setDonationAmount(100);
          }}
          text="$100"
          theme="primary"
          type="button"
        />
      </div>
      <div className="messageContainer" id="message">
        <TextArea
          label="Message"
          name="Message TextArea"
          placeholder="Type your message here"
          width="100%"
          onChange={(event) => setDonationMessage(event.target.value)}
          labelBgColor="#282c34"
          validation={{
            characterMaxLength: 255,
          }}
        />
      </div>
      <div className="donateButtonContainer">
        <Button
          icon="metamask"
          id="donateButton"
          onClick={selectedTokenAllowance > 0 ? startDonationFlow : approveToken}
          text={selectedTokenAllowance > 0 ? "Donate" : "Approve"}
          theme="outline"
          type="button"
        />
      </div>
      {txResult && (
        <div>
          <Modal
            id="regular"
            cancelText="Back to Home"
            okText="Make a new donation"
            isVisible
            onCancel={function goBackToHome() {
              window.location.replace(`${process.env.REACT_APP_HOST_URL}`);
            }}
            onCloseButtonPressed={function goBackToHome() {
              window.location.replace(`${process.env.REACT_APP_HOST_URL}`);
            }}
            onOk={function reloadPage() {
              window.location.reload();
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Icon fill={txResult.iconColor} size={64} svg={txResult.icon} />
              {txResult.hash != "" ? (
                <pre id="modalText">
                  {txResult.message}:
                  <a
                    href={chainId == BSC_CHAIN_ID ? `https://bscscan.com/tx/${txResult.hash}` : `https://etherscan.io/tx/${txResult.hash}`}
                    target="_blank"
                  >
                    {txResult.hash}
                  </a>
                </pre>
              ) : (
                <pre id="modalText">{txResult.message}</pre>
              )}
            </div>
          </Modal>
        </div>
      )}
      {(!streamerUsername ||
        !selectedTokenPrice ||
        !selectedTokenDecimals ||
        isTxPending ||
        !streamerWalletAddress) && (
          <div className="loader">
            <Loading size={50} spinnerColor="#ffffff" spinnerType="wave" />
          </div>
        )}
    </div>
  );
}

export default Donate;
