import "./Home.css";
import { Button, Input, Loading } from "web3uikit";
import { useMoralisCloudFunction } from "react-moralis";
import { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

function Home() {
  const [streamerUsername, setStreamerUsername] = useState();
  const [isSearchingStreamer, setIsSearchingStreamer] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  const {
    fetch: fetchStreamer,
    data: streamerResponse,
    error: errorStreamer,
    isLoading: isLoadingStreamer,
  } = useMoralisCloudFunction(
    "searchStreamer",
    {
      streamerUsername: streamerUsername,
    },
    { autoFetch: false }
  );

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(function () {
        setShowSnackbar(false);
      }, 2000);
    }
  }, [showSnackbar]);

  const searchStreamer = async () => {
    if (!streamerUsername) return;
    setIsSearchingStreamer(true);
    const streamer = await fetchStreamer();
    setIsSearchingStreamer(false);
    if (streamer) {
      navigate(`/donate?streamer=${streamerUsername.toLowerCase()}`, {});
    } else {
      setShowSnackbar(true);
    }
  };


  return (
    <div className="mainContainerHome">
      <div className="homeFlexContainer">
        <div className="infoContainerHome">
          <h1>Welcome to Dono Service!</h1>

          <p>
            Our service allows you to donate various cryptocurrency to your
            favourite streamers! Start by typing the username of the streamer you
            want to support.
          </p>
        </div>
        <div className="streamerContainer">
          <img
            width={60}
            src="https://cdn-icons-png.flaticon.com/512/5172/5172561.png"
          />
          <p id="areYouAStreamerTitle">Are you a streamer?</p>

          <Button
            id="createAccountHomeButton"
            onClick={function noRefCheck() {
              navigate("/createAccount", {});
            }}
            text="Create an account"
            theme="outline"
            type="button"
          />
        </div>
      </div>
      <div className="searchStreamerContainer" id="searchStreamer">
        <Input
          label="Streamer Username"
          name="Streamer Username Input"
          labelBgColor="#282c34"
          theme="primary"
          width="100%"
          onChange={(event) => setStreamerUsername(event.target.value)}
        />
        <Button
          id="searchStreamerButton"
          onClick={searchStreamer}
          text="Search"
          theme="primary"
          type="button"
        />
      </div>
      {showSnackbar &&
        <div id="snackbar">Streamer not found</div>
      }
      {isSearchingStreamer &&
        <div className="loader">
          <Loading
            spinnerColor="#ffffff"
            size={50}
          />
        </div>
      }
    </div>
  );
}

export default Home;
