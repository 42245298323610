import "./AccountCreated.css";
import { Button, Input, Loading, Icon } from "web3uikit";
import { useMoralisCloudFunction } from "react-moralis";
import { useState, useEffect } from "react";
import { useSearchParams, useLocation, Link } from "react-router-dom";

function AccountCreated() {
  const location = useLocation();
  const username = location.state.username;
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(function () {
        setShowSnackbar(false);
      }, 2000);
    }
  }, [showSnackbar]);
  return (
    <div className="mainContainerAccountCreated">
      <div className="titleContainerAccountCreated">
        <h1>Your account has been created!</h1>
      </div>
      {username && (
        <div className="infoContainerAccountCreated">
          <p>
            This is your personal link to receive donation:
            <a href={`${process.env.REACT_APP_HOST_URL}/donate?streamer=${username}`}>
            {process.env.REACT_APP_HOST_URL}?streamer={username}
            </a>
          </p>
          <Button
            icon="copy"
            iconLayout="icon-only"
            id="copyButton"
            onClick={function copyText() {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_HOST_URL}/donate?streamer=${username}`
              );
              setShowSnackbar(true);
            }}
            text="Copy"
            theme="secondary"
            type="button"
          />
        </div>
      )}
      <div className="createAccountButtonContainer">
        <Link to="/">
          <Button
            id="goBackToHomeButton"
            //onClick={goToHome}
            text="Go back to Home"
            theme="outline"
            type="button"
          />
        </Link>
      </div>

      {showSnackbar && 
      <div id="snackbar">Link copied to clipboard!</div>
      }
    </div>
  );
}

export default AccountCreated;
