import "./EditAccount.css";
import { Button, Input, Loading, Modal, Icon, Dropdown } from "web3uikit";
import { useMoralisCloudFunction } from "react-moralis";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

function EditAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const username = location.state.username;
  const accessToken = location.state.accessToken;
  const [newWalletAddress, setNewWalletAddress] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const {
    fetch: editAccount,
    data: editAccountResponse,
    error: editAccountError,
  } = useMoralisCloudFunction(
    "editStreamerWallet",
    {
      username: username,
      accessToken: accessToken,
      newWalletAddress: newWalletAddress,
    },
    { autoFetch: false }
  );

  const editStreamerAccount = async () => {
    if (!newWalletAddress) return;
    setIsLoading(true);
    const editAccountResult = await editAccount();
    console.log(editAccountResult);

    if (editAccountResult) {
      setErrorMessage(null);
      console.log(editAccountResult);
      setShowSnackbar(true);
      setTimeout(function () {
        setIsLoading(false);
        setShowSnackbar(false);
        navigate("/", {
          state: { username: username, accessToken: accessToken },
        });
      }, 2000);
    } else {
      setIsLoading(false);
      setErrorMessage("An error occured! Please, try again later.");
    }
  };
  useEffect(() => {
    if (editAccountError) {
      console.log(editAccountError);
      setIsLoading(false);
      setErrorMessage("An error occured! Please, try again later.");
    }
  }, [editAccountError]);

  return (
    <div className="mainContainerEditAccount">
      <div className="walletAddressContainer" id="walletInput">
        <Input
          width="100%"
          label="New Wallet Address"
          name="New Wallet Address"
          labelBgColor="#282c34"
          value={newWalletAddress}
          onChange={(event) => setNewWalletAddress(event.target.value)}
        />
      </div>

      <div className="createAccountButtonContainer">
        <Button
          id="createAccountButton"
          onClick={editStreamerAccount}
          text="Confirm"
          theme="outline"
          type="button"
        />
      </div>

      {errorMessage && (
        <div>
          <Modal
            id="regular"
            cancelText="Back to Home"
            okText="Ok"
            isVisible
            onCancel={function goBackToHome() {
              setErrorMessage(null);
              window.location.replace(`${process.env.REACT_APP_HOST_URL}`);
            }}
            onCloseButtonPressed={() => {
              setErrorMessage(null);
            }}
            onOk={() => {
              setErrorMessage(null);
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Icon fill="#dc2d2d" size={64} svg="exclamation" />
              <pre id="errorText">{errorMessage}</pre>
            </div>
          </Modal>
        </div>
      )}
      {showSnackbar && <div id="snackbar">Wallet address updated!</div>}
      {isLoading && (
        <div className="loader">
          <Loading spinnerColor="#ffffff" size={50} />
        </div>
      )}
    </div>
  );
}

export default EditAccount;
