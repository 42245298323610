import "./Login.css";
import { Button, Input, Loading, Modal, Icon, Dropdown } from "web3uikit";
import { useMoralisCloudFunction } from "react-moralis";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [accessToken, setAccessToken] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const {
    fetch: login,
    data: loginResponse,
    error: loginError,
  } = useMoralisCloudFunction(
    "loginStreamer",
    {
      username: username,
      password: password,
    },
    { autoFetch: false }
  );

  const loginStreamer = async () => {
    if (!username || !password) return;
    setIsLoading(true);
    const loginResult = await login();
    console.log(loginResult);
    setIsLoading(false);
    if (loginResult != "Denied") {
      setErrorMessage(null);
      console.log(loginResult);
      setAccessToken(loginResult);
    } else {
      setErrorMessage("Login failed!");
    }
  };
  useEffect(() => {

    if (loginError) {
      console.log(loginError);
      setIsLoading(false);
      setErrorMessage(
        "Login failed!"
      );
    }
  }, [loginError]);

  useEffect(() => {
    if (accessToken) {
      navigate("/editAccount", {
        state: { username: username, accessToken: accessToken },
      });
    }
  }, [accessToken]);

  return (
    <div className="mainContainerLoginAccount">
      <div className="usernameContainerLogin" id="usernameInputLogin">
        <Input
          label="Your Username"
          name="Username Input"
          labelBgColor="#282c34"
          theme="primary"
          width="100%"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
      </div>

      <div className="passwordContainerLogin" id="passwordInputLogin">
        <Input
          label="Your Password"
          name="Password Input"
          labelBgColor="#282c34"
          theme="primary"
          width="100%"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>

      <div className="loginAccountButtonContainer">
        <Button
          id="loginAccountButton"
          onClick={loginStreamer}
          text="Login"
          theme="outline"
          type="button"
        />
      </div>

      {errorMessage && (
        <div>
          <Modal
            id="regular"
            cancelText="Back to Home"
            okText="Ok"
            isVisible
            onCancel={function goBackToHome() {
              setErrorMessage(null);
              window.location.replace(`${process.env.REACT_APP_HOST_URL}`);
            }}
            onCloseButtonPressed={() => {
              setErrorMessage(null);
            }}
            onOk={() => {
              setErrorMessage(null);
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Icon fill="#dc2d2d" size={64} svg="exclamation" />
              <pre id="errorText">{errorMessage}</pre>
            </div>
          </Modal>
        </div>
      )}
      {isLoading && (
        <div className="loader">
          <Loading
            spinnerColor="#ffffff"
            size={50}
          />
        </div>
      )}
    </div>
  );
}

export default Login;
