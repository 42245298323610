import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { ConnectButton, Icon, Dropdown, CryptoLogos } from "web3uikit";
import { useMoralis, useChain } from "react-moralis";
import { Layout } from "antd";
import Donate from "./pages/Donate";
import CreateAccount from "./pages/CreateAccount";
import AccountCreated from "./pages/AccountCreated";
import Home from "./pages/Home";
import Login from "./pages/Login";
import EditAccount from "./pages/EditAccount";
import { useState, useEffect } from "react";
import logo from './images/logo.png'


const { Header, Content, Footer } = Layout;

function App() {
  const { enableWeb3, isWeb3Enabled, web3 } = useMoralis();
  const { switchNetwork, chainId, chain } = useChain();
  const [selectedChainId, setSelectedChainId] = useState();


  useEffect(() => {
    if (!isWeb3Enabled) return;
    if (!selectedChainId) {
      if (chainId == '0x38' || chainId == '0x1') {
        setSelectedChainId(chainId);
      } else {
        setSelectedChainId('0x38');
      }
    }
    if (chainId != selectedChainId) {
      console.log(chainId);
      console.log(chain);
      switchNetwork(selectedChainId);
    }
  }, [isWeb3Enabled, selectedChainId, chainId]);

  useEffect(() => {
    console.log(isWeb3Enabled);
    if (!isWeb3Enabled) {
      enableWeb3();
    }
  }, [isWeb3Enabled]);
  return (
    <Layout>
      <Header>
        <div id="header">
          <Link to="/">
            <img
              className="headerLogo"
              src={logo}
              alt="Header Image"
              height={50}
            />
          </Link>
          <div id="connectButtonContainer">
            {chainId &&
              <div className="chainDropdown" id="chainDropdown">
                <Dropdown
                  width="100%"
                  label="Chain: "
                  onChange={(selectedOption) => {
                    setSelectedChainId(selectedOption.id);
                  }}
                  showSelected
                  defaultOptionIndex={chainId == '0x38' ? 0 : 1}
                  options={[
                    {
                      id: "0x38",
                      label: "BSC",
                      prefix: <CryptoLogos
                        chain="binance"
                        size="24px"
                      />,
                    },
                    {
                      id: "0x1",
                      label: "ETH",
                      prefix: <CryptoLogos
                        chain="ethereum"
                        size="24px"
                      />,
                    },
                  ]}
                />
              </div>
            }
            <ConnectButton className="connectButton" />
          </div>
        </div>
      </Header>
      <Content className="contentWindow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/donate" element={<Donate selectedChainId={chainId} />} />
          <Route path="/createAccount" element={<CreateAccount />} />
          <Route path="/login-redirect" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/editAccount" element={<EditAccount />} />
          <Route path="/createAccountSuccess" element={<AccountCreated />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default App;
