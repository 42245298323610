import "./CreateAccount.css";
import { Button, Input, Loading, Modal, Icon, Dropdown } from "web3uikit";
import { useMoralisCloudFunction } from "react-moralis";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

function CreateAccount() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [authCode, setAuthCode] = useState();
  const [accessToken, setAccessToken] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const {
    fetch: createStreamer,
    data: streamerResponse,
    error: streamerError,
  } = useMoralisCloudFunction(
    "addStreamer",
    {
      username: username,
      password: password,
      walletAddress: walletAddress,
      accessToken: accessToken,
    },
    { autoFetch: false }
  );

  const {
    fetch: getAccessToken,
    data: accessTokenResponse,
    error: errorAccessToken,
    isLoading: isLoadingAccessToken,
  } = useMoralisCloudFunction(
    "getAccessToken",
    {
      code: authCode,
    },
    { autoFetch: false }
  );

  const tryCreateAccount = async () => {
    if (!username || !password || !walletAddress) return;
    setIsSavingData(true);
    window.sessionStorage.setItem("streamerUsername", username);
    window.sessionStorage.setItem("streamerPassword", password);
    window.sessionStorage.setItem("streamerWalletAddress", walletAddress);

    window.location.replace(
      `https://www.streamlabs.com/api/v1.0/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=alerts.create+donations.create`
    );
  };

  useEffect(() => {
    if (searchParams.has("code")) {
      const code = searchParams.get("code");
      if (code) {
        setIsSavingData(true);
        setAuthCode(code);
        setUsername(window.sessionStorage.getItem("streamerUsername"));
        setPassword(window.sessionStorage.getItem("streamerPassword"));
        setWalletAddress(
          window.sessionStorage.getItem("streamerWalletAddress")
        );
        searchParams.delete("code");
        setSearchParams(searchParams);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      getAccessToken();
    };

    // call the function
    if (authCode) {
      fetchData();
    }
  }, [authCode]);

  useEffect(() => {
    if (accessTokenResponse) {
      var accessToken = JSON.parse(accessTokenResponse).access_token;
      console.log(accessToken);
      setAccessToken(accessToken);
    }
  }, [accessTokenResponse]);

  useEffect(() => {
    const saveData = async () => {
      if (accessToken) {
        createStreamer();
      } else {
        setIsSavingData(false);
      }
    };
    if (accessToken) {
      saveData();
    }
  }, [accessToken]);

  useEffect(() => {
    if (streamerResponse) {
      setIsSavingData(false);
      if (streamerResponse == "-1") {
        console.log("username taken");
        setErrorMessage(
          "Username already taken. Please try with something else."
        );
      } else {
        setErrorMessage(null);
        navigate("/createAccountSuccess", {
          state: { username: username },
        });
      }
    }
    if (streamerError) {
      console.log(streamerError);
      setIsSavingData(false);
      setErrorMessage(
        "An error occured while creating your account. Please try again later."
      );
    }
  }, [streamerResponse, streamerError]);

  return (
    <div>
      <div className="titleContainerCreateAccount">
        <h1>Create an account</h1>
      </div>
      <div className="infoContainerCreateAccount">
        <p>Choose your username and insert the wallet address where you want to receive donations. You will then be redirect to a page to connect your Streamlabs account to our website. When everything is done, share your donation link with your audience!</p>
      </div>
      <div className="mainContainerCreateAccount">
        <div className="usernameContainer" id="usernameInput">
          <Input
            label="Your Username"
            name="Username Input"
            labelBgColor="#282c34"
            theme="primary"
            width="100%"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>

        <div className="passwordContainer" id="passwordInput">
          <Input
            label="Your Password"
            name="Password Input"
            labelBgColor="#282c34"
            theme="primary"
            width="100%"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        <div className="walletAddressContainer" id="walletInput">
          <Input
            width="100%"
            label="Wallet Address"
            name="Wallet Address"
            labelBgColor="#282c34"
            value={walletAddress}
            onChange={(event) => setWalletAddress(event.target.value)}
          />
        </div>

        <div className="createAccountButtonContainer">
          <Button
            id="createAccountButton"
            onClick={tryCreateAccount}
            text="Create Account"
            theme="outline"
            type="button"
          />
        </div>
        <div>
          <a style={{
            alignItems: "center",
            display: "flex",
            fontSize: "16px",
            marginTop: "0.5rem",
            justifyContent: "center",
          }} href={`${process.env.REACT_APP_HOST_URL}/login`}>Login</a>
        </div>
        {errorMessage && (
          <div>
            <Modal
              id="regular"
              cancelText="Back to Home"
              okText="Ok"
              isVisible
              onCancel={function goBackToHome() {
                setErrorMessage(null);
                window.location.replace(`${process.env.REACT_APP_HOST_URL}`);
              }}
              onCloseButtonPressed={() => {
                setErrorMessage(null);
              }}
              onOk={() => {
                setErrorMessage(null);
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Icon fill="#dc2d2d" size={64} svg="exclamation" />
                <pre id="errorText">{errorMessage}</pre>
              </div>
            </Modal>
          </div>
        )}
        {isSavingData &&
          <div className="loader">
            <Loading
              spinnerColor="#ffffff"
              size={50}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default CreateAccount;
